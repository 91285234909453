import config from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import { ACTIVISM } from '../constants/ProductConstants';
import api from '../api';

export const GetActivismNotifiedHoldingsToolList = async (req) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(
      config.getActivismNotifiedHoldingsToolData,
      {
        product_id: ACTIVISM,
        token,
        company_search_id: req.company_search_id,
        investor_search_id: req.investor_search_id
      },
      {
        cancelToken: req.cancelToken,
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export default { GetActivismNotifiedHoldingsToolList };
