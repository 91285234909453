import React, { useState, lazy, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import TableAgGridv31Wrapper from './Table/TableAgGridv31Wrapper';

const Table = (props) => {
  const DESIRED_TABLE = process.env.REACT_APP_DESIRED_TABLE ? process.env.REACT_APP_DESIRED_TABLE : 'AgGrid';

 if (DESIRED_TABLE === 'AgGrid') {
  return (
    <TableAgGridv31Wrapper {...props} />
  );
 }
};
Table.propTypes = {
  IsShowCard: PropTypes.bool,
  addedClass: PropTypes.string,
  gridOptions: PropTypes.object.isRequired,
  gridOptionsFooter: PropTypes.object,
  hideExcelDownloadIcon: PropTypes.bool,
  isComp: PropTypes.bool,
  isInvest: PropTypes.bool,
  pageTitle: PropTypes.string,
  smalltitle: PropTypes.string,
  title: PropTypes.string,
  extratitle: PropTypes.string,
  cancelSlideBottomToUp: PropTypes.bool,
  showInfoLable: PropTypes.any,
  linebreakInfoLable: PropTypes.any,
  isLandscape: PropTypes.bool,
  isFullWidth: PropTypes.bool
};

Table.defaultProps = {
  IsShowCard: true,
  addedClass: '',
  hideExcelDownloadIcon: false,
  isComp: false,
  isInvest: false,
  pageTitle: '',
  smalltitle: '',
  title: '',
  extratitle: '',
  cancelSlideBottomToUp: false,
  showInfoLable: undefined,
  linebreakInfoLable: undefined,
  isLandscape: false,
  isFullWidth: false,
};
export default React.memo(Table);
