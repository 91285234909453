import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../../utils/axios-base-query';

const initialState = { data: [] };

export const utilsApi = createApi({
  reducerPath: 'utilsApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v2' }),
  endpoints: (builder) => ({
    getFetureToggles: builder.query({
      query: ({ params }) => ({
        url: '/utils/getFeatureToggles',
        method: 'GET',
        body: params,
      }),
    }),
  }),
});

export const { useGetFetureTogglesQuery } = utilsApi;
