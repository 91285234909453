/* eslint-disable prefer-template */
import React, { useState, useCallback } from 'react';
import Card from './Card';

function TablePDF({
  IsShowCard,
  cancelSlideBottomToUp,
  isComp,
  isInvest,
  title,
  extratitle,
  smalltitle,
  addedClass,
  isHeaderInfoTooltip,
  gridOptions,
  repeatedColIndex,
  limitedColumns,
  isLandscape,
  isFullWidth,
}) {
  const [tableData, setTableData] = useState();
  const dataDynamicColumns = (allColumns, limit, repeatedColIndex) => {
    const totalLoog = allColumns.length / limit;
    const finalLoopVal = totalLoog.toString().includes('.') ? Number(Math.trunc(totalLoog)) + 1 : totalLoog.toFixed(0);
    const allColumnsCopy = allColumns;
    const repeatedCols = allColumnsCopy.splice(repeatedColIndex, 1);
    const originalData = [];
    for (let i = 1; i <= finalLoopVal; i++) {
      originalData.push([...repeatedCols, ...allColumnsCopy.splice(0, limit)]);
    }
    return originalData;
  };
  // const dynamicallColsCallback = useCallback((gridOptions, repeatedColIndex, limitedColumns) => {
  //   const cols = dataDynamicColumns(gridOptions.colDefsMedalsIncluded, 10, 0);
  //   if (cols.length) {
  //     return cols.map((itemCols, i) => {
  //       const sample = 0;
  //       return (
  //         <div className={i > 0 ? 'pdfpagebreak' : ''} key={`div1-itemCols-${itemCols[i].headerName}`}>
  //           <table className='customPDfTable mb-3' border={1} key={`table-itemCols-${itemCols[i].headerName}`}>
  //             {/* <colgroup width='50' key={`colgroup-itemCols-${itemCols[i].headerName}`}>
  //               {itemCols.map((items, index) => {
  //                 if (items.children?.length) {
  //                   return items?.children.map((subItem) => (
  //                     // eslint-disable-next-line prefer-template
  //                     <col span='2' width={subItem?.minWidth + 'px'} />
  //                   ));
  //                 }
  //                 // eslint-disable-next-line prefer-template
  //                 return <col span='1' width={items?.minWidth} />;
  //               })}
  //             </colgroup> */}
  //             <thead>
  //               <tr key={`tr1-itemCols-${itemCols[i].headerName}`}>
  //                 {itemCols.map((items) => (
  //                   <th
  //                     colSpan={items?.children?.length}
  //                     // eslint-disable-next-line prefer-template
  //                     style={{
  //                       minWidth: items.minWidth + 'px',
  //                       maxWidth: items.maxWidth ? items.maxWidth + 'px' : '50%',
  //                       width: items.maxWidth ? items.maxWidth + 'px' : '50%',
  //                       display:
  //                         (items?.type && items?.type[0]?.includes('hiddenField')) ||
  //                         (items?.hide && items?.hide) ? 'none' : '',
  //                     }}
  //                     className={items?.isVerticel ? 'rotateCell' : ''}
  //                     minWidth={200}
  //                     data-hidden={items?.type && items?.type[0]?.includes('hiddenField')}
  //                     maxWidth={200}
  //                     key={`th1-items0-${items?.headerName}`}
  //                   >
  //                     <div>{items?.headerName}</div>
  //                   </th>
  //                 ))}
  //               </tr>
  //               <tr key={`tr2-itemCols-${itemCols[i].headerName}`}>
  //                 {itemCols.map((items) => {
  //                   if (items?.children?.length > 0) {
  //                     return items?.children.map((subItem) => {
  //                       const daa = 123;
  //                       // eslint-disable-next-line prefer-template
  //                       return (
  //                         <th
  //                           className={subItem?.isVerticel ? 'rotateCell' : ''}
  //                           minWidth={200}
  //                           maxWidth={200}
  //                           data-hidden={subItem?.type?.includes('hiddenField')}
  //                           style={{
  //                             minWidth: subItem.minWidth + 'px',
  //                             maxWidth: subItem.maxWidth + 'px',
  //                             width: subItem.maxWidth + 'px',
  //                             display:
  //                               (subItem?.type && subItem?.type[0]?.includes('hiddenField')) ||
  //                               (subItem?.hide && subItem?.hide) ? 'none' : '',
  //                           }}
  //                         >
  //                           <div>{subItem?.headerName}</div>
  //                         </th>
  //                       );
  //                     });
  //                   }
  //                 })}
  //               </tr>
  //             </thead>
  //             <tbody key={`tb1-itemCols-${itemCols[i].headerName}`}>
  //               {gridOptions.rowData &&
  //                 gridOptions.rowData.map((itemData, index1) => (
  //                   <tr key={`tr5-itemData-${itemData.investor_id}`}>
  //                     {itemCols.map((items, index) => {
  //                       if (items.children?.length) {
  //                         return items?.children.map((subItem) => {
  //                           let fields = '';
  //                           fields = subItem?.cellRenderer
  //                             ? subItem?.cellRenderer({ data: itemData, value: itemData[subItem?.field] })
  //                             : subItem?.cellRendererFramework
  //                             ? subItem?.cellRendererFramework({ data: itemData, value: itemData[subItem?.field] })
  //                             : itemData[subItem?.field];
  //                           if (fields?.outerHTML) {
  //                             return (
  //                               <td
  //                                 style={{
  //                                   display:
  //                                     (subItem?.type && subItem?.type[0]?.includes('hiddenField')) ||
  //                                     (subItem?.hide && subItem?.hide) ? 'none' : '',
  //                                 }}
  //                                 aria-label='td'
  //                                 dangerouslySetInnerHTML={{ __html: fields?.outerHTML }}
  //                               />
  //                             );
  //                           }
  //                           if (!fields?.outerHTML && fields !== '') {
  //                             return (
  //                               <td
  //                                 style={{
  //                                   display:
  //                                     (subItem?.type && subItem?.type[0]?.includes('hiddenField')) ||
  //                                     (subItem?.hide && subItem?.hide) ? 'none' : '',
  //                                 }}
  //                               >
  //                                 {fields}
  //                               </td>
  //                             );
  //                           }
  //                           return (
  //                             <td
  //                               dangerouslySetInnerHTML={{ __html: fields }}
  //                               aria-label='td'
  //                               style={{
  //                                 display:
  //                                   (subItem?.type && subItem?.type[0]?.includes('hiddenField')) ||
  //                                   (subItem?.hide && subItem?.hide) ? 'none' : '',
  //                               }}
  //                             />
  //                           );
  //                         });
  //                       }
  //                       let fields = '';
  //                       fields = items?.cellRenderer
  //                         ? items?.cellRenderer({ data: itemData, value: itemData[items?.field] })
  //                         : items?.cellRendererFramework
  //                         ? items?.cellRendererFramework({ data: itemData, value: itemData[items?.field] })
  //                         : itemData[items?.field];
  //                       if (fields?.outerHTML) {
  //                         return (
  //                           <td
  //                             aria-label='td'
  //                             style={{
  //                               display:
  //                                 (items?.type && items?.type[0]?.includes('hiddenField')) ||
  //                                 (items?.hide && items?.hide) ? 'none' : '',
  //                             }}
  //                             dangerouslySetInnerHTML={{ __html: fields?.outerHTML }}
  //                           />
  //                         );
  //                       }
  //                       if (!fields?.outerHTML && fields !== '') {
  //                         return (
  //                           <td
  //                             style={{
  //                               display:
  //                                 (items?.type && items?.type[0]?.includes('hiddenField')) ||
  //                                 (items?.hide && items?.hide) ? 'none' : '',
  //                             }}
  //                           >
  //                             {' '}
  //                             {fields}
  //                           </td>
  //                         );
  //                       }
  //                       return (
  //                         <td
  //                           aria-label='td'
  //                           style={{
  //                             display:
  //                               (items?.type && items?.type[0]?.includes('hiddenField')) ||
  //                               (items?.hide && items?.hide) ? 'none' : '',
  //                           }}
  //                           dangerouslySetInnerHTML={{ __html: fields }}
  //                         />
  //                       );
  //                     })}
  //                   </tr>
  //                 ))}
  //             </tbody>
  //           </table>
  //         </div>
  //       );
  //     });
  //   }
  // }, [gridOptions, gridOptions.colDefsMedalsIncluded]);
  // dynamicallColsCallback(gridOptions, repeatedColIndex, limitedColumns);

  const dynamicallCols = (gridOptions, repeatedColIndex, limitedColumns) => {
    const cols = dataDynamicColumns(gridOptions.colDefsMedalsIncluded, 10, 0);
    if (cols.length) {
      return cols.map((itemCols, i) => {
        const sample = 0 + i;
        return (
          <div className={i > 0 ? 'pdfpagebreak' : ''} key={`div1-itemCols-${itemCols[0].headerName}-${sample}`}>
            <table
              className='customPDfTable mb-3'
              border={1}
              key={`table-itemCols-${itemCols[0].headerName}-${sample}`}
            >
              <thead>
                <tr key={`tr1-itemCols-${itemCols[0].headerName}-${sample}`}>
                  {itemCols.map((items) => (
                    <th
                      colSpan={items?.children?.length}
                      // eslint-disable-next-line prefer-template
                      style={{
                        minWidth: items.minWidth + 'px',
                        maxWidth: items.maxWidth ? items.maxWidth + 'px' : '50%',
                        width: items.maxWidth ? items.maxWidth + 'px' : '50%',
                        display:
                          (items?.type && items?.type[0]?.includes('hiddenField')) ||
                          (items?.hide && items?.hide) ? 'none' : '',
                      }}
                      className={items?.isVerticel ? 'rotateCell' : ''}
                      minWidth={200}
                      data-hidden={items?.type && items?.type[0]?.includes('hiddenField')}
                      maxWidth={200}
                      key={`th1-items0-${items?.headerName}`}
                    >
                      <div>{items?.headerName}</div>
                    </th>
                  ))}
                </tr>
                <tr key={`tr2-itemCols-${itemCols[0].headerName}-${sample}`}>
                  {itemCols.map((items) => {
                    if (items?.children?.length > 0) {
                      return items?.children.map((subItem) => {
                        const daa = 123;
                        // eslint-disable-next-line prefer-template
                        return (
                          <th
                            className={subItem?.isVerticel ? 'rotateCell' : ''}
                            minWidth={200}
                            maxWidth={200}
                            data-hidden={subItem?.type?.includes('hiddenField')}
                            style={{
                              minWidth: subItem.minWidth + 'px',
                              maxWidth: subItem.maxWidth + 'px',
                              width: subItem.maxWidth + 'px',
                              display:
                                (subItem?.type && subItem?.type[0]?.includes('hiddenField')) ||
                                (subItem?.hide && subItem?.hide) ? 'none' : '',
                            }}
                          >
                            <div>{subItem?.headerName}</div>
                          </th>
                        );
                      });
                    }
                  })}
                </tr>
              </thead>
              <tbody key={`tb1-itemCols-${itemCols[0].headerName}-${sample}`}>
                {gridOptions.rowData &&
                  gridOptions.rowData.map((itemData, index1) => (
                    <tr key={`tr5-itemData-${itemData.investor_id}`}>
                      {itemCols.map((items, index) => {
                        if (items.children?.length) {
                          return items?.children.map((subItem) => {
                            let fields = '';
                            fields = subItem?.cellRenderer
                              ? subItem?.cellRenderer({ data: itemData, value: itemData[subItem?.field] })
                              : subItem?.cellRendererFramework
                              ? subItem?.cellRendererFramework({ data: itemData, value: itemData[subItem?.field] })
                              : itemData[subItem?.field];
                            if (fields?.outerHTML) {
                              return (
                                <td
                                  style={{
                                    display:
                                      (subItem?.type && subItem?.type[0]?.includes('hiddenField')) ||
                                      (subItem?.hide && subItem?.hide) ? 'none' : '',
                                  }}
                                  aria-label='td'
                                  dangerouslySetInnerHTML={{ __html: fields?.outerHTML }}
                                />
                              );
                            }
                            if (!fields?.outerHTML && fields !== '') {
                              return (
                                <td
                                  style={{
                                    display:
                                      (subItem?.type && subItem?.type[0]?.includes('hiddenField')) ||
                                      (subItem?.hide && subItem?.hide) ? 'none' : '',
                                  }}
                                >
                                  {fields}
                                </td>
                              );
                            }
                            return (
                              <td
                                dangerouslySetInnerHTML={{ __html: fields }}
                                aria-label='td'
                                style={{
                                  display:
                                    (subItem?.type && subItem?.type[0]?.includes('hiddenField')) ||
                                    (subItem?.hide && subItem?.hide) ? 'none' : '',
                                }}
                              />
                            );
                          });
                        }
                        let fields = '';
                        fields = items?.cellRenderer
                          ? items?.cellRenderer({ data: itemData, value: itemData[items?.field] })
                          : items?.cellRendererFramework
                          ? items?.cellRendererFramework({ data: itemData, value: itemData[items?.field] })
                          : itemData[items?.field];
                        if (fields?.outerHTML) {
                          return (
                            <td
                              aria-label='td'
                              style={{
                                display:
                                  (items?.type && items?.type[0]?.includes('hiddenField')) ||
                                  (items?.hide && items?.hide) ? 'none' : '',
                              }}
                              dangerouslySetInnerHTML={{ __html: fields?.outerHTML }}
                            />
                          );
                        }
                        if (!fields?.outerHTML && fields !== '') {
                          return (
                            <td
                              style={{
                                display:
                                  (items?.type && items?.type[0]?.includes('hiddenField')) ||
                                  (items?.hide && items?.hide) ? 'none' : '',
                              }}
                            >
                              {' '}
                              {fields}
                            </td>
                          );
                        }
                        return (
                          <td
                            aria-label='td'
                            style={{
                              display:
                                (items?.type && items?.type[0]?.includes('hiddenField')) ||
                                (items?.hide && items?.hide) ? 'none' : '',
                            }}
                            dangerouslySetInnerHTML={{ __html: fields }}
                          />
                        );
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        );
      });
    }
  };
  return (
    <Card
      IsShowCard={IsShowCard}
      cancelSlideBottomToUp={cancelSlideBottomToUp}
      isComp={isComp}
      isInvest={isInvest}
      title={title}
      extratitle={extratitle}
      smalltitle={smalltitle}
      addedClass={addedClass}
      isHeaderInfoTooltip={isHeaderInfoTooltip}
    >
      {gridOptions.limitedColumns ? (
        <>{dynamicallCols(gridOptions, repeatedColIndex, limitedColumns)}</>
      ) : (
        <table
          className='customPDfTable'
          border={1}
          style={{
            maxWidth: isLandscape ? '1080px' : !isFullWidth ? '740px' : '',
            width: isLandscape ? '1080px' : !isFullWidth ? '740px' : '',
          }}
        >
          <colgroup width='50'>
            {gridOptions.colDefsMedalsIncluded.map((items, index) => {
              if (items.children?.length) {
                return items.children.map((subItem) => {
                  const data123 = 1;
                  return (
                    // eslint-disable-next-line prefer-template
                    <col
                      span='2'
                      width={subItem?.minWidth + 'px'}
                      key={`colgroup3-items-${subItem?.headerName}-${subItem?.field}`}
                    />
                  );
                });
              }
              // eslint-disable-next-line prefer-template
              return <col span='1' width={items?.minWidth} />;
            })}
          </colgroup>
          <thead>
            <tr>
              {gridOptions.colDefsMedalsIncluded.map((items) => (
                <th
                  colSpan={items?.children?.length}
                  // eslint-disable-next-line prefer-template
                  style={{
                    minWidth: items.minWidth + 'px',
                    maxWidth: items.maxWidth ? items.maxWidth + 'px' : '50%',
                    width: items.maxWidth ? items.maxWidth + 'px' : '50%',
                    display:
                      (items?.type && items?.type[0]?.includes('hiddenField')) ||
                      (items?.hide && items?.hide) ? 'none' : '',
                  }}
                  className={items?.isVerticel ? 'rotateCell' : ''}
                  minWidth={200}
                  data-hidden={items?.type && items?.type[0]?.includes('hiddenField')}
                  maxWidth={200}
                  key={`th-colDef-${items?.headerName}`}
                >
                  <div>{items?.headerName}</div>
                </th>
              ))}
            </tr>
            <tr>
              {gridOptions.colDefsMedalsIncluded.map((items) => {
                if (items?.children?.length > 0) {
                  return items.children.map((subItem) => {
                    const daa = 123;
                    // eslint-disable-next-line prefer-template
                    return (
                      <th
                        className={subItem?.isVerticel ? 'rotateCell' : ''}
                        minWidth={200}
                        maxWidth={200}
                        data-hidden={subItem?.type?.includes('hiddenField')}
                        style={{
                          minWidth: subItem.minWidth + 'px',
                          maxWidth: subItem.maxWidth + 'px',
                          width: subItem.maxWidth + 'px',
                          display:
                            (subItem?.type && subItem?.type[0]?.includes('hiddenField')) ||
                            (subItem?.hide && subItem?.hide) ? 'none' : '',
                        }}
                        key={`th2-subItem-${subItem?.headerName}`}
                      >
                        <div>{subItem?.headerName}</div>
                      </th>
                    );
                  });
                }
              })}
            </tr>
          </thead>
          <tbody>
            {gridOptions.rowData &&
              gridOptions.rowData.map((itemData, index) => (
                <tr key={`tr3-itemData-${itemData.No}-${itemData.proposal_id}-${'index' + index}`}>
                  {gridOptions.colDefsMedalsIncluded.map((items, index) => {
                    if (items.children?.length) {
                      return items.children.map((subItem) => {
                        let fields = '';
                        fields = subItem?.cellRenderer
                          ? subItem?.cellRenderer({ data: itemData, value: itemData[subItem?.field] })
                          : subItem?.cellRendererFramework
                          ? subItem?.cellRendererFramework({ data: itemData, value: itemData[subItem?.field] })
                          : itemData[subItem?.field];
                        if (fields?.outerHTML) {
                          return (
                            <td
                              aria-label='data1'
                              colSpan={
                                subItem?.colSpan &&
                                subItem?.colSpan({ data: itemData, value: itemData[subItem?.field] })
                              }
                              style={{
                                display:
                                  (subItem?.type && subItem?.type[0]?.includes('hiddenField')) ||
                                  fields === undefined ||
                                  (subItem?.hide && subItem?.hide) ? 'none' : '',
                              }}
                              className={subItem?.cellClass}
                              dangerouslySetInnerHTML={{ __html: fields?.outerHTML }}
                              key={`td3-itemData-${subItem?.headerName}-${subItem?.type}`}
                            />
                          );
                        }
                        if (!fields?.outerHTML && fields !== '') {
                          return (
                            <td
                              style={{
                                display:
                                  (subItem?.type && subItem?.type[0]?.includes('hiddenField')) ||
                                  fields === undefined ||
                                  (subItem?.hide && subItem?.hide) ? 'none' : '',
                              }}
                              colSpan={
                                subItem?.colSpan &&
                                subItem?.colSpan({ data: itemData, value: itemData[subItem?.field] })
                              }
                              key={`td4-itemData-${subItem?.headerName}-${subItem?.type}`}
                            >
                              {fields}
                            </td>
                          );
                        }
                        return (
                          <td
                            aria-label='data1'
                            colSpan={
                              subItem?.colSpan && subItem?.colSpan({ data: itemData, value: itemData[subItem?.field] })
                            }
                            dangerouslySetInnerHTML={{ __html: fields }}
                            style={{
                              display:
                                (subItem?.type && subItem?.type[0]?.includes('hiddenField')) ||
                                fields === undefined ||
                                (subItem?.hide && subItem?.hide) ? 'none' : '',
                            }}
                            key={`td5-itemData-${subItem?.headerName}-${subItem?.type}`}
                          />
                        );
                      });
                    }
                    let fields = '';
                    fields = items?.cellRenderer
                      ? items?.cellRenderer({ data: itemData, value: itemData[items?.field] })
                      : items?.cellRendererFramework
                      ? items?.cellRendererFramework({ data: itemData, value: itemData[items?.field] })
                      : itemData[items?.field];
                    if (fields?.outerHTML) {
                      return (
                        <td
                          style={{
                            display:
                              (items?.type && items?.type[0]?.includes('hiddenField')) ||
                              fields === undefined ||
                              (items?.hide && items?.hide) ? 'none' : '',
                          }}
                          aria-label='data1'
                          colSpan={items?.colSpan && items?.colSpan({ data: itemData, value: itemData[items?.field] })}
                          dangerouslySetInnerHTML={{ __html: fields?.outerHTML }}
                          className={items?.cellClass}
                          key={`td6-itemData-${items?.headerName}-${items?.type}`}
                        />
                      );
                    }
                    if (!fields?.outerHTML && fields) {
                      return (
                        <td
                          style={{
                            display:
                              (items?.type && items?.type[0]?.includes('hiddenField')) || fields === undefined || (items?.hide && items?.hide) ? 'none' : '',
                          }}
                          colSpan={items?.colSpan && items?.colSpan({ data: itemData, value: itemData[items?.field] })}
                          key={`td7-itemData-${items?.headerName}-${items?.type}`}
                          className={items?.cellClass}
                        >
                          {fields}
                        </td>
                      );
                    }
                    return (
                      <td
                        style={{
                          display:
                            (items?.type && items?.type[0]?.includes('hiddenField')) ||
                            fields === undefined ||
                            (items?.hide && items?.hide) ? 'none' : '',
                        }}
                        aria-label='data1'
                        dangerouslySetInnerHTML={{ __html: fields }}
                        className={items?.cellClass}
                        colSpan={items?.colSpan && items?.colSpan({ data: itemData, value: itemData[items?.field] })}
                        key={`td8-itemData-${items?.headerName}-${items?.type}`}
                      />
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </Card>
  );
}

export default React.memo(TablePDF);
