import { GOVERNANCE_CORPORATE_DEFENSE_SCOREDATA_TOOL_TOOLTIP_SECTION_ID } from '../constants/sectionTooltipsIdConstamt';
import { getCorporateDefenseScoreData } from '../config/server-config';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import { TokenDecodeForProductStatus } from './general-util';
import { GOVERNANCE } from '../constants/ProductConstants';
import api from '../api';

export const GetCorporateDefenseScoreData = async (company_search_id) => {
  try {
    const status = await TokenDecodeForProductStatus(GOVERNANCE);
    const response = await api.post(
      getCorporateDefenseScoreData,
      {
        company_search_id: company_search_id,
        tooltip_section_id:
          GOVERNANCE_CORPORATE_DEFENSE_SCOREDATA_TOOL_TOOLTIP_SECTION_ID,
        status,
      }
    );
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export default { GetCorporateDefenseScoreData };
