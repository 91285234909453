import axios from 'axios';
import jwt from 'jsonwebtoken';
import config from './config/server-config';
import { isSsoBasedOnUrl } from './utils/app-utils';

const api = axios.create({
  withCredentials: true,
  baseURL: createBaseUrl(),
});

function createBaseUrl() {
  if (
    process.env.NODE_ENV === 'development' ||
    window.location.hostname === 'stageapp.insightia.com' ||
    window.location.hostname === 'one.insightia.com'
  ) {
    return '';
  }

  // edge case for dev (both frontend points to the London backend)
  if (window.location.hostname.endsWith('dmi.highbond-s3.com')) {
    return 'https://api.dmi-uk.highbond-s3.com';
  }

  return `https://api.${window.location.hostname.split('.').slice(-3).join('.')}`;
}

api.interceptors.request.use((config) => {
  const token = `Bearer ${localStorage.getItem('token')}`;
  const isHighbondUser = isSsoBasedOnUrl(window.location.hostname);
  if (isHighbondUser) {
    config.headers.isHighbondUser = isHighbondUser;
  }
  config.headers.Authorization = token;
  config.headers['Content-Type'] = 'application/json';
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async ({ response }) => {
    if (response.status === 401 && response.headers['token-expired'] === 'true') {
      api
        .put(process.env.TOKEN_REFRESH_URL)
        .then(() => {})
        .catch((err) => console.log(err));
    }

    if (response.status === 401 && response.headers['missing-authentication-cookie'] === 'true') {
      const decode = jwt.decode(window.localStorage.getItem('token'));
      if (!decode) return response;
      const userid = decode.User_Id;
      const sessionid = decode.Session_Id;

      await axios.post(config.logout, { userid, sessionid });

      localStorage.removeItem('token');
      localStorage.removeItem('userEmail');
      localStorage.removeItem('notificationData');
      localStorage.removeItem('alertNotificationData');
      localStorage.removeItem('investorFilterData');
      localStorage.removeItem('companyFilterData');
      localStorage.removeItem('userInfo');

      const logoutUrl = `https://accounts.${window.location.origin.split('.').slice(-2).join('.')}/logout`;
      window.location.href = logoutUrl;
    }

    return response;
  },
);

export default api;
