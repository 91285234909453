import React, { useEffect, useState } from 'react';
import { OidcClient } from 'oidc-client-ts';
import {
  withRouter,
  Redirect,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { authenticateViaHighbond } from '../../utils/login-util';
import { API_CALL_SUCCESSFULL } from '../../constants/NumberConstants';
import { oidcLoginAction } from '../../features/v2/User/user-slice';

async function getUserInfo(highbondSub, saveUser) {
  try {
    let sub = 'https://oidc-us';
    if (window.location.origin.endsWith('highbond.com')) {
      sub = 'https://oidc-eu';
    }
    const authority = `${sub}.${highbondSub}`;

    const client = new OidcClient({
      authority,
      client_id: 'dmi_app',
      redirect_uri: `${window.location.origin}/login/callback`,
      response_type: 'code',
    });

    if (window.location.pathname === '/login/callback') {
      // Exchange code for access token
      try {
        const { access_token } = await client.processSigninResponse(
          window.location.href
        );

        // Call /userinfo
        const userInfoEndpoint =
          await client.metadataService.getUserInfoEndpoint();
        const headers = { authorization: `Bearer ${access_token}` };
        const response = await fetch(userInfoEndpoint, { headers });
        const userInfo = await response.json();

        window.localStorage.setItem('userInfo', JSON.stringify(userInfo));

        const res = await authenticateViaHighbond();

        if (res?.status === API_CALL_SUCCESSFULL) {
          saveUser(res.userid);
          return { success: true, status: 'fulfilled' };
        }
        if (res?.status === 403) {
          return { success: false, status: 'error', text: res.message };
        }
        if (res?.status === 401) {
          window.location.href = `https://${highbondSub}/login?redirect=${window.location.href}`;
        }
      } catch (error) {
        console.error('Error processing signin response:', error);
      }
    } else {
      // Send user to authenticate with OIDC
      const scope = 'openid email profile membership';
      const { url } = await client.createSigninRequest({ scope });
      window.location.href = url;
      return {
        success: false,
        status: 'loginInProgress',
        text: 'Welcome to Market Intelligence.',
      };
    }
  } catch (error) {
    console.error('Error processing signin response:', error);
    return {
      success: false,
      status: 'error',
      text: 'Oops! Something went wrong while signing you in. If you continue to experience issues, please contact our support team for assistance. We apologize for any inconvenience.',
    };
  }
}

const SSO = () => {
  const dispatch = useDispatch();
  const highbondSub = window.location.origin.split('.').slice(-2).join('.');

  const [isAuthenticated, setIsAuthenticated] = useState({ status: 'pending' });

  const saveUser = (userid) => dispatch(oidcLoginAction(userid));

  useEffect(() => {
    if (isAuthenticated.status !== 'pending') return;

    getUserInfo(highbondSub, saveUser).then((result) => {
      setIsAuthenticated({ status: result.status, message: result.text });
    });
  }, []);

  const handleBackToHighbond = () => {
    window.location.href = `https://${highbondSub}`;
  };

  switch (isAuthenticated.status) {
    case 'fulfilled':
      return (
        <Redirect
          to={
            window.sessionStorage.getItem('redirect')
              ? window.sessionStorage.getItem('redirect')
              : '/'
          }
        />
      );
    case 'rejected':
      return (
        <div
          style={{ display: 'flex', height: '100vh', justifyContent: 'center' }}
        >
          <h1 style={{ display: 'flex', alignItems: 'center' }}>
            {isAuthenticated.message}
          </h1>
        </div>
      );
    case 'loginInProgress':
      return (
        <div
          style={{ display: 'flex', height: '100vh', justifyContent: 'center', textAlign: 'center' }}
        >
          <h1 style={{ display: 'flex', alignItems: 'center' }}>
            {isAuthenticated.message}
          </h1>
        </div>
      );
    case 'error':
      return (
        <div
          style={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0 100px',
          }}
        >
          <h1 style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            {isAuthenticated.message}
          </h1>
          <button
            onClick={handleBackToHighbond}
            className="btn btn-primary"
            type="submit"
            style={{ width: '200px' }}
          >
            Back to Highbond
          </button>
        </div>
      );
    case 'pending':
    default:
      return (
        <div
          style={{ display: 'flex', height: '100vh', justifyContent: 'center' }}
        >
          <h1 style={{ display: 'flex', alignItems: 'center' }}>
            Loading data...
          </h1>
        </div>
      );
  }
};

export default withRouter(SSO);
