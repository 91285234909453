import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetActivistShortsFillingsData } from '../../../utils/toolsAiSFeelingsSeach-utis';

export const getActivistShortsFillingsDataReq = createAsyncThunk('getActivistShortsFillingsDataReq', async (cancelToken) => {
  const response = await GetActivistShortsFillingsData(cancelToken);
  return response;
});

const AiSFillingsSearchSlice = createSlice({
  name: 'aisfillingssearch',
  initialState: {
    lstFillingSearchData: [],
    isLoadingFillingData: true
  },
  reducers: {
    handleResetAiSFiling: {
      reducer(state, action) {
        state.isLoadingFillingData = true;
        state.lstFillingSearchData = [];
      },
      prepare(e) {
        return {
          payload: e
        };
      }
    }
  },
  extraReducers: {
    [getActivistShortsFillingsDataReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.isLoadingFillingData = !action.payload;
        state.lstFillingSearchData = action.payload ? action.payload : [];
      }
    }
  }
});

export const {
  handleResetAiSFiling
} = AiSFillingsSearchSlice.actions;
export default AiSFillingsSearchSlice.reducer;
