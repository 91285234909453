export const ARRAY_START_VALUE = 0;
export const ARRAY_HAS_NO_LENGTH = 0;
export const ARRAY_POSITION_ONE = 1;
export const NUMBER_ADJUST_ONE = 1;
export const NUMBER_NEGATIVE_ONE = -1;
export const NUMBER_POSITIVE_ONE = 1;
export const ALPHA_RANDOM_STRING_SIZE = 36;
export const RANDOM_SUBSTRING_FROM = 2;
export const RANDOM_SUBSTRING_TO = 9;
export const API_CALL_SUCCESSFULL = 200;
export const KEY_CODE_SAPCE = 32;
export const TRIAL_USER = 2;
export const FULL_USER = 4;
export const LAST_MEMBERSHIP_ITEM = 7;
export const FORBIDDEN_USER = 7;
export const OWNERSHIP_PRODUCT_COUNT = 4;

export const NUMBER_ZERO = 0;
export const NUMBER_ONE = 1;
export const NUMBER_TWO = 2;
export const NUMBER_THREE = 3;
export const NUMBER_FOUR = 4;
export const NUMBER_FIVE = 5;
export const NUMBER_SIX = 6;
export const NUMBER_SEVEN = 7;
export const NUMBER_EIGHT = 8;
export const NUMBER_NINE = 9;
export const NUMBER_TEN = 10;
export const NUMBER_ELEVEN = 11;
export const NUMBER_TWELVE = 12;
export const NUMBER_THIRTEEN = 13;
export const NUMBER_FOURTEEN = 14;
export const NUMBER_FIFTEEN = 15;
export const NUMBER_SIXTEEN = 16;
export const NUMBER_SEVENTEEN = 17;
export const NUMBER_EIGHTEEN = 18;
export const NUMBER_NINETEEN = 19;
export const NUMBER_TWENTY = 20;
export const NUMBER_TWENTYONE = 21;
export const NUMBER_TWENTYTHREE = 23;
export const NUMBER_THIRTYONE = 31;
export const NUMBER_FOURTYONE = 41;
export const NUMBER_FIFTYONE = 51;
export const NUMBER_SIXTYONE = 61;
export const NUMBER_SIXTYTWO = 62;
export const NUMBER_HUNDRED = 100;
export const NUMBER_ONE_TWO_ONE = 121;
export const NUMBER_ONE_THREE_ONE = 131;
export const NUMBER_ONE_FOUR_ONE = 141;
export const NUMBER_ONE_FIVE_ONE = 151;
export const NUMBER_ONE_FIVE_TWO = 152;
export const NUMBER_ONE_SIX_ONE = 161;
export const NUMBER_ONE_SIX_TWO = 162;
export const NUMBER_ONE_SEVEN_ONE = 171;
export const NUMBER_ONE_SEVEN_TWO = 172;
export const NUMBER_ONE_SEVEN_THREE = 173;
export const NUMBER_ONE_EIGHT_ONE = 181;
export const NUMBER_ONE_NINE_ONE = 191;
export const NUMBER_ONE_NINE_TWO = 192;
export const NUMBER_TRIPLE_ONE = 111;
export const NUMBER_TRIPLE_NINE = 999;
export const NUMBER_THOUSAND = 1000;
export const NUMBER_MARKET_CAP_MAX = 2000000000;
export const NUMBER_MARKET_CAP_MIN = 0;
export const NUMBER_TWO_HUNDRED = 200;
export const NUMBER_FIFTY = 50;
export const YEAR_YYYY_RESTRICT = 9999;

export const EMPTY_TABLE_LENGTH = 0;

export const INTERVAL_TIME = 30000;
export const FIVE_SECOND_TIMEOUT = 5000;
export const ARR_INDENTED_VALUES = ['Cash Bonus', 'Non-Equity Incentive Plan', 'Shares', 'Options', 'LTI Cash', 'Deferred Cash', 'Deferred Shares', 'Unvested Restricted Shares', 'Unvested Performance Shares', 'Unexercisable Options', 'Exercisable Options'];

export const ASYNC_SEARCH_START_POSITION = 0;
export const ASYNC_SEARCH_MAX_LENGTH = 50;
export const ASYNC_SEARCH_END_POSITION = ASYNC_SEARCH_MAX_LENGTH - NUMBER_ONE;

export default {
  ARRAY_START_VALUE,
  ARRAY_HAS_NO_LENGTH,
  ARRAY_POSITION_ONE,
  NUMBER_ADJUST_ONE,
  NUMBER_NEGATIVE_ONE,
  NUMBER_POSITIVE_ONE,
  ALPHA_RANDOM_STRING_SIZE,
  RANDOM_SUBSTRING_FROM,
  RANDOM_SUBSTRING_TO,
  API_CALL_SUCCESSFULL,
  KEY_CODE_SAPCE,
  TRIAL_USER,
  FULL_USER,
  LAST_MEMBERSHIP_ITEM,
  FORBIDDEN_USER,

  NUMBER_ZERO,
  NUMBER_ONE,
  NUMBER_TWO,
  NUMBER_THREE,
  NUMBER_FOUR,
  NUMBER_FIVE,
  NUMBER_SIX,
  NUMBER_SEVEN,
  NUMBER_EIGHT,
  NUMBER_NINE,
  NUMBER_TEN,
  NUMBER_ELEVEN,
  NUMBER_TWELVE,
  NUMBER_THIRTEEN,
  NUMBER_FOURTEEN,
  NUMBER_FIFTEEN,
  NUMBER_SIXTEEN,
  NUMBER_SEVENTEEN,
  NUMBER_EIGHTEEN,
  NUMBER_NINETEEN,
  NUMBER_TWENTY,
  NUMBER_TWENTYONE,
  NUMBER_TWENTYTHREE,
  NUMBER_THIRTYONE,
  NUMBER_FOURTYONE,
  NUMBER_FIFTYONE,
  NUMBER_SIXTYONE,
  NUMBER_SIXTYTWO,
  NUMBER_HUNDRED,
  NUMBER_ONE_TWO_ONE,
  NUMBER_ONE_THREE_ONE,
  NUMBER_ONE_FOUR_ONE,
  NUMBER_ONE_FIVE_ONE,
  NUMBER_ONE_FIVE_TWO,
  NUMBER_ONE_SIX_ONE,
  NUMBER_ONE_SIX_TWO,
  NUMBER_ONE_SEVEN_ONE,
  NUMBER_ONE_SEVEN_TWO,
  NUMBER_ONE_SEVEN_THREE,
  NUMBER_ONE_EIGHT_ONE,
  NUMBER_ONE_NINE_ONE,
  NUMBER_ONE_NINE_TWO,
  NUMBER_TRIPLE_ONE,
  NUMBER_TRIPLE_NINE,
  NUMBER_THOUSAND,
  EMPTY_TABLE_LENGTH,
  INTERVAL_TIME,
  NUMBER_MARKET_CAP_MAX,
  NUMBER_MARKET_CAP_MIN,
  NUMBER_TWO_HUNDRED,
  NUMBER_FIFTY,
  FIVE_SECOND_TIMEOUT,
  ARR_INDENTED_VALUES,
  ASYNC_SEARCH_START_POSITION,
  ASYNC_SEARCH_MAX_LENGTH,
  ASYNC_SEARCH_END_POSITION,
  YEAR_YYYY_RESTRICT
};
