import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetGovernanceScores } from '../../../utils/toolsGovernanceScore-utils';

export const getGovernanceScoresReq = createAsyncThunk(
  'getGovernanceScoresReq',
  async (req) => {
    const response = await GetGovernanceScores(req);
    return response;
  }
);

const GovernanceScoreToolsSlice = createSlice({
  name: 'governancescoretool',
  initialState: {
    lstGovernanceScores: [],
    headerTooltips: [],
    isLoadingGovernanceScores: true,
  },
  reducers: {
    handleResetGovernanceScore: {
      reducer(state, action) {
        state.lstGovernanceScores = [];
        state.headerTooltips = [];
        state.isLoadingGovernanceScores = true;
      },
      prepare(e) {
        return {
          payload: e
        };
      }
    }
  },
  extraReducers: {
    [getGovernanceScoresReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.lstGovernanceScores =
          action.payload !== undefined ? action.payload.data : [];
        state.headerTooltips =
          action.payload !== undefined ? action.payload.tooltipData : [];
      }
      state.isLoadingGovernanceScores = false;
    },
  },
});
export const {
  handleResetGovernanceScore
} = GovernanceScoreToolsSlice.actions;

export default GovernanceScoreToolsSlice.reducer;
