import { sanitizeUrl } from '@braintree/sanitize-url';
import config from '../config/server-config';
import { TokenDecode } from './general-util';
import { API_CALL_SUCCESSFULL } from '../constants/NumberConstants';
import api from '../api';

// Investor
export const GetInvestorProfile = async (investor) => {
  try {
    const response = await api.post(config.getInvestorProfile, {
      investor: investor,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetInvestorNavReq = async (investor) => {
  try {
    let uid;
    await TokenDecode()
      .then((res) => {
        uid = res.User_Id;
      })
      .catch((e) => console.log(e));

    const response = await api.post(config.getInvestorNavReq, {
      investor_id: investor,
      user_id: uid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const investor_search = async (nameSearch, quicksearch, limit, offset, cancelToken, isFullSearchResult) => {
  try {
    const response = await getRequestFunction();
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }

  function getRequestFunction() {
    const sanitizedName = sanitizeUrl(nameSearch);
    if (isFullSearchResult) {
      return api.get(config.investor_searchV2, { params: { nameSearch: sanitizedName }, cancelToken: cancelToken });
    }
    if (quicksearch) {
      return api.get(config.investor_pagedQuicksearchV2, {
        params: { nameSearch: sanitizedName, limit, offset },
        cancelToken: cancelToken,
      });
    }
    return api.get(config.investor_pagedSearchV2, {
      params: { nameSearch: sanitizedName, limit, offset },
      cancelToken: cancelToken,
    });
  }
};

// Voting Rationale
export const GetVotingRationale_byInvestor = async (investor_id) => {
  try {
    const response = await api.post(config.getVotingRationale_byInvestor, {
      investor_id: investor_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// Investor filings
export const listActivistFilingsByActivist_v2 = async (company_id, activist_id, longShort) => {
  try {
    const response = await api.post(config.listActivistFilingsByActivist_v2, {
      company_id,
      activist_id,
      longShort,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const listActivistFilingsByActivistAiS = async (company_id, activist_id, longShort) => {
  try {
    const response = await api.post(config.listActivistFilingsByActivistAiS, {
      company_id,
      activist_id,
      longShort,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// Investor voting search
export const GetVotedByManagerList = async (investor_id) => {
  try {
    const response = await api.post(config.getVotedByManagerList, {
      investor_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// Investor Overview
export const GetFMProfile = async (investor_id) => {
  try {
    const response = await api.post(config.getFMProfile, {
      investor_id: Number(investor_id),
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

// Investor voting overview
export const GetInvestorVoteSummary = async (investor_id) => {
  try {
    const response = await api.post(config.getInvestorVoteSummary, {
      investor_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const IssAndglasslewis_vote = async (investor_id) => {
  try {
    const response = await api.post(config.issAndglasslewis_vote, {
      investor_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetManager_voting_against = async (investor_id) => {
  try {
    const response = await api.post(config.getManager_voting_against, {
      investor_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetDissident_Data_for_Investor_v2 = async (investorid) => {
  try {
    const response = await api.post(config.getDissident_Data_for_Investor_v2, {
      investorid,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};

export const GetManager_latest_against2 = async (investor_id) => {
  try {
    const response = await api.post(config.getManager_latest_against2, {
      investor_id,
    });
    if (response.status === API_CALL_SUCCESSFULL) {
      return response.data;
    }
  } catch (e) {
    return false;
  }
};
