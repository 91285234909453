import React, { useState, lazy, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Markup } from 'interweave';
import TableAgGridv31 from './TableAgGridv31';

const TableAgGridv31Wrapper = (props) => {
  const newGridOptions = props.gridOptions;

  newGridOptions?.colDefsMedalsIncluded?.forEach((y, t) => {
    if (y?.children) {
      y?.children?.forEach((x, i) => {
        if (x?.cellRenderer) {
          //x.cellRenderer = <Markup content={x.cellRenderer} />;
          //x.cellRenderer.name = ""
        }
        if (x?.cellRendererFramework) {
          x.cellRenderer = x.cellRendererFramework;
          x.cellRendererFramework = undefined;
        }
      });
    } else {
      if (y?.cellRendererFramework) {
        y.cellRenderer = y.cellRendererFramework;
        y.cellRendererFramework = undefined;
      }
    }
  });

  return (
    <>
      <TableAgGridv31 {...props} gridOptions={newGridOptions} />
    </>
  );
};
  TableAgGridv31Wrapper.propTypes = {
  IsShowCard: PropTypes.bool,
  addedClass: PropTypes.string,
  gridOptions: PropTypes.object.isRequired,
  gridOptionsFooter: PropTypes.object,
  hideExcelDownloadIcon: PropTypes.bool,
  isComp: PropTypes.bool,
  isInvest: PropTypes.bool,
  pageTitle: PropTypes.string,
  smalltitle: PropTypes.string,
  title: PropTypes.string,
  extratitle: PropTypes.string,
  cancelSlideBottomToUp: PropTypes.bool,
  showInfoLable: PropTypes.any,
  linebreakInfoLable: PropTypes.any,
  isLandscape: PropTypes.bool,
  isFullWidth: PropTypes.bool
};

TableAgGridv31Wrapper.defaultProps = {
  IsShowCard: true,
  addedClass: '',
  hideExcelDownloadIcon: false,
  isComp: false,
  isInvest: false,
  pageTitle: '',
  smalltitle: '',
  title: '',
  extratitle: '',
  cancelSlideBottomToUp: false,
  showInfoLable: undefined,
  linebreakInfoLable: undefined,
  isLandscape: false,
  isFullWidth: false,
};
export default React.memo(TableAgGridv31Wrapper);
