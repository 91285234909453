import React, { useState, useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function LensWrapper({ children }) {
  const [themeProvider, setThemeProvider] = useState(<></>);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_IS_LENS === 'true') {
      const promises = [];
      promises.push(import('@diligentcorp/atlas-theme-mui/lib/themes/lens/index'));
      promises.push(import('@diligentcorp/atlas-theme-mui'));
      promises.push(import('@diligentcorp/atlas-mui-license'));

      setIsLoading(true);

      Promise.all(promises)
        .then(([lensThemeOptionsModule, atlasThemeProviderModule, muiLicenseModule]) => {
          setThemeProvider(
            <atlasThemeProviderModule.AtlasThemeProvider themeOptions={lensThemeOptionsModule.lensThemeOptions}>
              {children}
            </atlasThemeProviderModule.AtlasThemeProvider>,
          );
          muiLicenseModule.muiLicense.apply();
        })
        .catch((error) => {
          console.error('Error loading the lens theme', error);
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  if (process.env.REACT_APP_IS_LENS === 'true') {
    if (isLoading) {
      return <div>Loading...</div>;
    }
    return themeProvider;
  }

  return children;
}
