import dateFormat from 'dateformat';
import { NUMBER_HUNDRED } from '../constants/NumberConstants';
import { dateToNull, dateToISOString } from './general-util';
import CustomVulnFiltersToolPanel from './AgGridVulnAdvancedSearchToolPanel';
// allowedAggFuncs: ['sum', 'min', 'max', 'count', 'avg', 'first', 'last'],

function dateComparator(date1, date2) {
  // DATE COMPARATOR FOR SORTING
  const date1Number = _monthToNum(date1);
  const date2Number = _monthToNum(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
}
function _monthToNum(dateAsString) {
  // HELPER FOR DATE COMPARISON
  const date = dateToNull(dateAsString, 'dd-mm-yyyy', true);
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }
  const yearNumber = date.substring(6, 10);
  const monthNumber = date.substring(3, 5);
  const dayNumber = date.substring(0, 2);

  const result = yearNumber * 10000 + monthNumber * 100 + dayNumber; // 29/08/2004 => 20040829
  return result;
}

export const filters = {
  nonEditableColumn: { editable: false },
  nonFloatingFilter: { floatingFilter: false },
  enableRowGroup: { enableRowGroup: true },
  rowGroup: { rowGroup: true },
  enableValue: { enableValue: true },
  hiddenField: { hide: true },
  nonHiddenField: { hide: false },
  sortableFalse: { sortable: false },
  sortableTrue: { sortable: true },
  aggFuncSum: { aggFunc: 'sum' },
  flex1: { flex: 1 },
  autoHeightTrue: { autoHeight: true },
  wrapTextTrue: { wrapText: true },
  sortDesc: { sort: 'desc' },
  sortAsc: { sort: 'asc' },
  // Filters
  numberColumn: {
    filter: 'agNumberColumnFilter',
    filterParams: {
      newRowsAction: 'keep',
      buttons: ['reset'],
    },
  },
  textColumn: {
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset', 'apply'],
    },
  },
  dateColumn: {
    filter: 'agDateColumnFilter',
    minWidth: 200,
    comparator: dateComparator,
    filterParams: {
      comparator(filterLocalDateAtMidnight, cellValue) {
        if (cellValue === null) {
          return 0;
        }
        const dateAsString = dateToNull(cellValue, 'dd-mm-yyyy', true);
        const dateParts = dateAsString.split('-');
        const day = Number(dateParts[0]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[2]);
        const cellDate = new Date(year, month, day);

        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
      debounceMs: 500,
      suppressAndOrCondition: false,
      browserDatePicker: true,
      newRowsAction: 'keep',
      buttons: ['reset'],
    },
  },
  setColumn: {
    filter: 'agSetColumnFilter',
    filterParams: {
      showTooltips: true,
      newRowsAction: 'keep',
      buttons: ['reset'],
      debounceMs: 200,
    },
  },
};

function isNull(obj, key) {
  return obj[key] === null || obj[key] === undefined || obj[key] === 'null';
}
export function validateNulltoEmptyString(obj) {
  const objKeys = Object.keys(obj);
  objKeys.forEach((key) => {
    if (isNull(obj, key)) {
      obj[key] = '';
    }
  });
}
export function NumberFormatFn(objNumber) {
  if (objNumber !== null && objNumber !== undefined) {
    const nf = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return nf.format(objNumber);
  }
  return '';
}

export function NumberFormatRevertFn(objNumber) {
  if (objNumber !== null && objNumber !== undefined) {
    // const b = objNumber.toString().replace(/\\,/g, '');
    const b = objNumber.toString().replace(/,/g, '');
    const a = Number(b);
    return a;
    // 1125, but a string, so convert it to number a=parseInt(a,10);
  }
  return 0;
}

export const distinctCounts = (params) => {
  const value = new Set(params.values).size;
  return value;
};

export const getQuickFilterText = (params) => params.value;

export const setNextGroupColumnText = (params) =>
  params.values.length > 0 ? params.values[0] : '';

export const converHistoricalTrendsFloatingNumber = (params) => {
  if (params.data.for_pcent === NUMBER_HUNDRED) {
    return parseFloat(params.data.for_pcent).toFixed(0);
  }
  return parseFloat(params.data.for_pcent).toFixed(1);
};

export const bottomStatusBar = {
  statusPanels: [
    { statusPanel: 'agTotalRowCountComponent', align: 'left' },
    { statusPanel: 'agFilteredRowCountComponent', align: 'left' },
    { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
    { statusPanel: 'agAggregationComponent', align: 'left' },
  ],
};

export const bottomStatusBarServerside = {
  statusPanels: [
    { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
    { statusPanel: 'agAggregationComponent', align: 'left' },
  ],
};

export const columnSidebar = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        hiddenByDefault: true,
      },
    },
  ],
};

export const filterSidebar = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
};

export const columnSidebarNoPivotNoColNoRow = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        hiddenByDefault: true,
        suppressValues: true,
        suppressPivotMode: true,
        suppressColumnFilter: true,
        suppressRowGroups: true,
      },
    },
  ],
};

export const columnAndfilterSidebar = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        hiddenByDefault: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        hiddenByDefault: true,
      },
    },
  ],
  position: 'right',
};

export const columnAndfilterSidebarNoPivotNoColNoRow = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        hiddenByDefault: true,
        suppressValues: true,
        suppressPivotMode: true,
        suppressColumnFilter: true,
        suppressRowGroups: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        hiddenByDefault: true,
      },
    },
  ],
  position: 'right',
};

export const columnAndfilterSidebarServerside = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        hiddenByDefault: true,
        suppressColumnSelectAll: true,
        suppressPivotMode: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        hiddenByDefault: true,
        suppressExpandAll: true,
      },
    },
  ],
  position: 'right',
};

export const columnAndfilterVulnAdvSearchSidebarServerside = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        hiddenByDefault: true,
        suppressColumnSelectAll: true,
        suppressPivotMode: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        hiddenByDefault: true,
        suppressExpandAll: true,
      },
    },
    {
      id: 'CustomVulnFiltersToolPanel',
      labelDefault: 'Advanced Search',
      labelKey: 'toolPanel',
      iconKey: 'filter',
      toolPanel: 'CustomVulnFiltersToolPanel',
      toolPanelParams: {
        hiddenByDefault: true,
        suppressExpandAll: true,
      },
    },
  ],
  position: 'right',
};

export const customVulnFiltersToolPanelComponent = {
  CustomVulnFiltersToolPanel: CustomVulnFiltersToolPanel,
};

export const loadingRenderer = {
  loadingRenderer(params) {
    if (params.value !== undefined) {
      return params.value;
    }
    return `<img src=${'https://raw.githubusercontent.com/ag-grid/ag-grid/master/grid-packages/ag-grid-docs/src/images/loading.gif'}>`;
  },
};

export function ChangeName(data) {
  if (data === 'tick.png') {
    return 'True';
  }
  if (data === 'redflag_large.png') {
    return 'False';
  }
  if (data === 'GreenYes.png') {
    return 'For';
  }
  if (data === 'RedCross.png') {
    return 'Against';
  }
  if (data === 'RedCrclWithLine.png') {
    return 'Stop';
  }
  if (data === 'OrangeCross.png') {
    return 'Warning';
  }
  if (data === 'deskIcon.png') {
    return 'Unknown or N/A';
  }
  if (data === 'Up.png') {
    return 'Up';
  }
  if (data === 'Down.png') {
    return 'Down';
  }
  if (data === 'SEC Accept No Action') {
    return 'SEC Accept No Action';
  }
  if (data === 'SEC Reject No Action') {
    return 'SEC Reject No Action';
  }
  if (data === 'Proponent Withdrew') {
    return 'Proponent Withdrew';
  }
  if (data === 'Company accepts and Proponent withdrew') {
    return 'Company accepts and Proponent withdrew';
  }
  if (data === 'Reconsideration Declined') {
    return 'Reconsideration Declined';
  }
  return data;
}

export function NameToLetter(data) {
  if (data === 'True') {
    return 'Healthy';
  }
  if (data === 'False') {
    return 'Unhealthy';
  }
}

export function NameToImageIcon(data) {
  if (data === 'True') {
    return 'greenflag_large.png';
  }
  if (data === 'False') {
    return 'redflag_large.png';
  }
  if (data === 'For' || data === 'Yes' || data === 'SEC Accept No Action') {
    return 'GreenYes.png';
  }
  if (data === 'Against' || data === 'SEC Reject No Action') {
    return 'RedCross.png';
  }
  if (data === 'Left' || data === 'Proponent Withdrew') {
    return 'Left.png';
  }
  if (data === 'Right' || data === 'Company accepts and Proponent withdrew') {
    return 'Right.png';
  }
  if (data === 'Unknown or N/A' || data === null) {
    return 'deskIcon.png';
  }
  if (data === 'Stop' || data === 'Reconsideration Declined') {
    return 'RedCrclWithLine.png';
  }
  if (data === 'Warning') {
    return 'OrangeCross.png';
  }
  if (data === 'Up') {
    return 'Up.png';
  }
  if (data === 'Down') {
    return 'Down.png';
  }
  if (data === 'Warn') {
    return 'exclaim.png';
  }
  // if (data === null) {
  //   return 'deskIcon.png';
  // }
  if (!data) {
    return '';
  }
  return data;
}

export function getCompanyNInvestorFilterCardInfo(props, isLoaded) {
  let CompanyLine = '';
  let InvestorLine = '';

  function emptyThenDashFn(data) {
    if (data !== '' && data !== undefined && data !== null) {
      return data;
    }
    return false;
  }
  // #region Company Filter
  if (isLoaded && props.isRunCompanyFilter) {
    const companySearchOptionSelection =
      props.companySearchOptionSelection !== undefined &&
      props.companySearchOptionSelection !== null
        ? emptyThenDashFn(props.companySearchOptionSelection.label)
        : '';
    if (companySearchOptionSelection && companySearchOptionSelection !== '') {
      CompanyLine += `Company Selection: ${companySearchOptionSelection}\n`;
    }

    const companySingleSelection =
      props.companySingleSelection !== undefined &&
      props.companySingleSelection !== null
        ? emptyThenDashFn(props.companySingleSelection.label)
        : '';
    if (companySingleSelection && companySingleSelection !== '') {
      CompanyLine += `Company(Indiv): ${companySingleSelection},\n`;
    }

    const companySelection =
      props.companySelection !== undefined &&
      props.companySelection !== null &&
      !props.companySelection.map((x) => x.label).every((x) => x === '')
        ? emptyThenDashFn(props.companySelection.map((x) => x.label).join(', '))
        : '';
    if (companySelection) {
      CompanyLine += `Companies: ${companySelection},\n`;
    }

    const indexSelection = emptyThenDashFn(
      !props.indexSelection.map((x) => x.label).every((x) => x === '')
        ? props.indexSelection.map((x) => x.label).join(', ')
        : ''
    );
    if (indexSelection) {
      CompanyLine += `Index: ${indexSelection},\n`;
    }

    const exchangeSelection = emptyThenDashFn(
      !props.exchangeSelection.map((x) => x.label).every((x) => x === '')
        ? props.exchangeSelection.map((x) => x.label).join(', ')
        : ''
    );
    if (exchangeSelection) {
      CompanyLine += `Exchange: ${exchangeSelection},\n`;
    }

    const aiPeerGroupSelection = emptyThenDashFn(
      !props.aiPeerGroupSelection.map((x) => x.label).every((x) => x === '')
        ? props.aiPeerGroupSelection.map((x) => x.label).join(', ')
        : ''
    );
    if (aiPeerGroupSelection) {
      CompanyLine += `AI Peer Groups (US): ${aiPeerGroupSelection},\n`;
    }

    const marketCapSelection =
      props.marketCapSelection !== undefined &&
      props.marketCapSelection !== null
        ? emptyThenDashFn(props.marketCapSelection.label)
        : '';
    if (marketCapSelection && marketCapSelection !== '') {
      CompanyLine += `Market Cap ($mn): ${marketCapSelection},\n`;
    }

    const marketCaprange =
      props.txtMarketCapMinRange !== '' && props.txtMarketCapMaxRange !== ''
        ? `${props.txtMarketCapMinRange} - ${props.txtMarketCapMaxRange}`
        : props.txtMarketCapMinRange !== '' && props.txtMarketCapMaxRange === ''
        ? `(min) ${props.txtMarketCapMinRange}`
        : props.txtMarketCapMinRange === '' && props.txtMarketCapMaxRange !== ''
        ? `(max) ${props.txtMarketCapMaxRange}`
        : '';
    if (marketCaprange && marketCaprange !== '') {
      CompanyLine += `Or use your own Market Cap range: ${marketCaprange},\n`;
    }

    const industrySelection = emptyThenDashFn(
      !props.industrySelection.map((x) => x.label).every((x) => x === '')
        ? props.industrySelection.map((x) => x.label).join(', ')
        : ''
    );
    if (industrySelection) {
      CompanyLine += `Industry: ${industrySelection},\n`;
    }

    const companyLocationSelection = emptyThenDashFn(
      !props.companyLocationSelection.map((x) => x.label).every((x) => x === '')
        ? props.companyLocationSelection.map((x) => x.label).join(', ')
        : ''
    );
    if (companyLocationSelection) {
      CompanyLine += `By Company Location: ${companyLocationSelection},`;
    }
    CompanyLine = CompanyLine.substring(
      0,
      CompanyLine.lastIndexOf(',', CompanyLine.length)
    );
  }
  // #endregion

  // #region Investor Filter
  if (isLoaded && props.isRunInvestorFilter) {
    const investorSearchOptionsSelection =
      props.investorSearchOptionsSelection !== undefined &&
      props.investorSearchOptionsSelection !== null
        ? emptyThenDashFn(props.investorSearchOptionsSelection.label)
        : '';
    if (
      investorSearchOptionsSelection &&
      investorSearchOptionsSelection !== ''
    ) {
      InvestorLine += `Investor Selection: ${investorSearchOptionsSelection}\n`;
    }

    const investorSelection =
      props.investorSelection !== undefined && props.investorSelection !== null
        ? emptyThenDashFn(
            !props.investorSelection.map((x) => x.label).every((x) => x === '')
              ? props.investorSelection.map((x) => x.label).join(', ')
              : ''
          )
        : '';
    if (investorSelection && investorSelection !== '') {
      InvestorLine += `Investors: ${investorSelection},\n`;
    }

    const investorLocationSelection =
      props.investorLocationSelection !== undefined &&
      props.investorLocationSelection !== null
        ? emptyThenDashFn(
            !props.investorLocationSelection
              .map((x) => x.label)
              .every((x) => x === '')
              ? props.investorLocationSelection.map((x) => x.label).join(', ')
              : ''
          )
        : '';
    if (investorLocationSelection && investorLocationSelection !== '') {
      InvestorLine += `By Investor Location: ${investorLocationSelection},\n`;
    }

    const invselectionByIndivProponent =
      props.selectionByIndivProponent !== undefined &&
      props.selectionByIndivProponent !== null
        ? emptyThenDashFn(props.selectionByIndivProponent.label)
        : '';
    if (invselectionByIndivProponent && invselectionByIndivProponent !== '') {
      InvestorLine += `By shareholder of Company (US only): ${invselectionByIndivProponent},\n`;
    }

    const invlistInvestorTypeAndSubtypeSelection =
      props.listInvestorTypeAndSubtypeSelection !== undefined &&
      props.listInvestorTypeAndSubtypeSelection !== null
        ? emptyThenDashFn(
            !props.listInvestorTypeAndSubtypeSelection
              .map((x) => x.label)
              .every((x) => x === '')
              ? props.listInvestorTypeAndSubtypeSelection
                  .map((x) => x.label)
                  .join(', ')
              : ''
          )
        : '';
    if (
      invlistInvestorTypeAndSubtypeSelection &&
      invlistInvestorTypeAndSubtypeSelection !== ''
    ) {
      InvestorLine += `Investor Type: ${invlistInvestorTypeAndSubtypeSelection},\n`;
    }

    const invaumCategorySelection =
      props.aumCategorySelection !== undefined &&
      props.aumCategorySelection !== null
        ? emptyThenDashFn(props.aumCategorySelection.label)
        : '';
    if (invaumCategorySelection && invaumCategorySelection !== '') {
      InvestorLine += `AUM ($bn): ${invaumCategorySelection},\n`;
    }

    let invmarketCapMaxRange = '';
    if (
      props.invTxtMarketCapMinRange !== '' &&
      props.invTxtMarketCapMaxRange !== ''
    ) {
      invmarketCapMaxRange = `${props.invTxtMarketCapMinRange} - ${props.invTxtMarketCapMaxRange}`;
    } else if (
      props.invTxtMarketCapMinRange !== '' &&
      props.invTxtMarketCapMaxRange === ''
    ) {
      invmarketCapMaxRange = `${
        props.invTxtMarketCapMinRange === '' ? 0 : props.invTxtMarketCapMinRange
      } - 0`;
    } else if (
      props.invTxtMarketCapMinRange === '' &&
      props.invTxtMarketCapMaxRange !== ''
    ) {
      invmarketCapMaxRange = `0 - ${
        props.invTxtMarketCapMaxRange === '' ? 0 : props.invTxtMarketCapMaxRange
      }`;
    } else {
      invmarketCapMaxRange = '';
    }
    if (invmarketCapMaxRange && invmarketCapMaxRange !== '') {
      InvestorLine += `or use your own AUM range: ${invmarketCapMaxRange},\n`;
    }
    InvestorLine = InvestorLine.substring(
      0,
      InvestorLine.lastIndexOf(',', InvestorLine.length)
    );
  }
  // #endregion

  return { CompanyLine, InvestorLine };
}

export function onFilterChangedExcelExportFilterCard(coldef, gridApi) {
  const obj = Object.keys(gridApi.getFilterModel());
  const array = [];

  obj.map((xkey) => {
    let valueHeader = '';
    function namechange(objVal) {
      let cond1Type = '';
      if (objVal === 'equals') {
        cond1Type = '(equals)';
      } else if (objVal === 'notequal') {
        cond1Type = '(not equal)';
      } else if (objVal === 'lessthan') {
        cond1Type = '(less than)';
      } else if (objVal === 'greaterthan') {
        cond1Type = '(greater than)';
      } else if (objVal === 'lessthanorequal') {
        cond1Type = '(less than or equal)';
      } else if (objVal === 'greaterthanorequal') {
        cond1Type = '(greater than or equal)';
      } else if (objVal === 'contains') {
        cond1Type = '(contains)';
      } else if (objVal === 'notcontains') {
        cond1Type = '(not contains)';
      } else if (objVal === 'startswith') {
        cond1Type = '(start with)';
      } else if (objVal === 'endswith') {
        cond1Type = '(end with)';
      }
      return cond1Type;
    }
    if (gridApi.getFilterModel()[xkey].filterType === 'date') {
      const andoperator = gridApi.getFilterModel()[xkey].operator;
      let val = '';
      if (andoperator) {
        const condition1valobj = gridApi.getFilterModel()[xkey].condition1;
        const condition1valtype = namechange(
          gridApi.getFilterModel()[xkey].condition1.type.toLowerCase()
        );
        const condition2valobj = gridApi.getFilterModel()[xkey].condition2;
        const condition2valtype = namechange(
          gridApi.getFilterModel()[xkey].condition2.type.toLowerCase()
        );
        const datefrom1 =
          condition1valobj.dateFrom !== null
            ? dateFormat(
                dateToISOString(new Date(condition1valobj.dateFrom)),
                'dd mmm yyyy',
                true
              )
            : '';
        const datefrom2 =
          condition2valobj.dateFrom !== null
            ? dateFormat(
                dateToISOString(new Date(condition2valobj.dateFrom)),
                'dd mmm yyyy',
                true
              )
            : '';
        if (condition2valobj.type === 'inRange') {
          const dateto1 =
            condition1valobj.dateTo !== null
              ? dateFormat(
                  dateToISOString(new Date(condition1valobj.dateTo)),
                  'dd mmm yyyy',
                  true
                )
              : '';
          const dateto2 =
            condition2valobj.dateTo !== null
              ? dateFormat(
                  dateToISOString(new Date(condition2valobj.dateTo)),
                  'dd mmm yyyy',
                  true
                )
              : '';
          val = `${condition1valtype} ${datefrom1} - ${dateto1} ${andoperator} ${condition2valtype} ${datefrom2} - ${dateto2}`;
        } else {
          val = `${condition1valtype} ${datefrom1} ${andoperator} ${condition2valtype} ${datefrom2}`;
        }
      } else {
        const valobj = gridApi.getFilterModel()[xkey];
        const valobjtype = gridApi.getFilterModel()[xkey].type.toLowerCase();
        const datefrom =
          valobj.dateFrom !== null
            ? dateFormat(
                dateToISOString(new Date(valobj.dateFrom)),
                'dd mmm yyyy',
                true
              )
            : '';
        const dateto =
          valobj.dateTo !== null
            ? dateFormat(
                dateToISOString(new Date(valobj.dateTo)),
                'dd mmm yyyy',
                true
              )
            : '';
        const valType = namechange(valobjtype);
        if (dateto !== '') {
          val = `${datefrom} - ${dateto}`;
        } else if (valType !== null && valType !== '') {
          val = `${valType} ${datefrom}`;
        } else {
          val = datefrom;
        }
      }
      valueHeader = val;
    } else if (gridApi.getFilterModel()[xkey].filterType === 'number') {
      const andoperator = gridApi.getFilterModel()[xkey].operator;
      let val = '';
      if (andoperator) {
        const obj1 = gridApi.getFilterModel()[xkey].condition1;
        const obj2 = gridApi.getFilterModel()[xkey].condition2;
        const condition1valobj = obj1.filter;
        const condition1valfilterTo = obj1.filterTo;
        const condition1valtype = obj1.type.toLowerCase();
        const condition2valobj = obj2.filter;
        const condition2valfilterTo = obj2.filterTo;
        const condition2valtype = obj2.type.toLowerCase();
        if (condition1valtype === 'inrange') {
          val = `(in range) ${condition1valobj}-${condition1valfilterTo}`;
        }
        if (condition2valtype === 'inrange') {
          val += ` ${andoperator} (in range) ${condition1valobj}-${condition2valfilterTo}`;
        } else {
          const cond1Type = namechange(condition1valtype);
          const cond2Type = namechange(condition2valtype);
          val = `${cond1Type} ${condition1valobj} ${andoperator} ${cond2Type} ${condition2valobj}`;
        }
      } else {
        const valobj = gridApi.getFilterModel()[xkey];
        const valtype = valobj.type.toLowerCase();
        const valfilter = valobj.filter;
        const valfilterTo = valobj.filterTo;
        if (valtype === 'inrange') {
          val = `(in range) ${valfilter}-${valfilterTo}`;
        } else {
          const objValType = namechange(valtype);
          val = `${objValType} ${valfilter}`;
        }
      }
      valueHeader = val;
    } else if (gridApi.getFilterModel()[xkey].filterType === 'text') {
      const andoperator = gridApi.getFilterModel()[xkey].operator;
      let val = '';
      if (andoperator) {
        const obj1 = gridApi.getFilterModel()[xkey].condition1;
        const obj2 = gridApi.getFilterModel()[xkey].condition2;
        const condition1valobj = obj1.filter;
        const condition1valfilterTo = obj1.filterTo;
        const condition1valtype = obj1.type.toLowerCase();
        const condition2valobj = obj2.filter;
        const condition2valfilterTo = obj2.filterTo;
        const condition2valtype = obj2.type.toLowerCase();
        if (condition1valtype === 'inrange') {
          val = `(in range) ${condition1valobj}-${condition1valfilterTo}`;
        }
        if (condition2valtype === 'inrange') {
          val += ` ${andoperator} (in range) ${condition1valobj}-${condition2valfilterTo}`;
        } else {
          const cond1Type = namechange(condition1valtype);
          const cond2Type = namechange(condition2valtype);
          val = `${cond1Type} ${condition1valobj} ${andoperator} ${cond2Type} ${condition2valobj}`;
        }
      } else {
        const valobj = gridApi.getFilterModel()[xkey];
        const valtype = valobj.type.toLowerCase();
        const valfilter = valobj.filter;
        const valfilterTo = valobj.filterTo;
        if (valtype === 'inrange') {
          val = `(in range) ${valfilter}-${valfilterTo}`;
        } else {
          const objValType = namechange(valtype);
          val = `${objValType} ${valfilter}`;
        }
      }
      valueHeader = val;
    } else {
      const displayedValuesData = gridApi
        .getFilterInstance(xkey)
        .getValueModel().displayedValues;

      const valData = gridApi.getFilterModel()[xkey].values;
      let comb = '';
      valData.map((x) => {
        if (Object.keys(displayedValuesData).length > 0) {
          displayedValuesData.map((y) => {
            if (x !== null && y !== null && x.toString() === y.toString()) {
              comb += `${x.toString()}, `;
            }
          });
        }
      });
      valueHeader = comb !== null ? comb.substring(0, comb.length - 2) : '';
    }
    /////////////
    let headerName = '';
    const haveArrayFieldCol =
      coldef !== null ? coldef.filter((x) => x.field).length : 0;
    if (haveArrayFieldCol > 0) {
      if (coldef.filter((x) => x.field === xkey).length > 0) {
        headerName = coldef.filter((x) => x.field === xkey)[0].headerName;
      }
    } else {
      headerName =
        coldef !== null &&
        coldef
          .map((x) => {
            if (x.children.filter((x) => x.field === xkey).length > 0) {
              return x.children.filter((x) => x.field === xkey)[0].headerName;
            }
          })
          .filter((y) => y)
          .toString();
    }
    array.push(
      `${headerName}: ${valueHeader.trim() !== '' ? valueHeader : '-'}`
    );
  });
  return array;
}

export default {
  distinctCounts,
  getQuickFilterText,
  setNextGroupColumnText,
  converHistoricalTrendsFloatingNumber,
  ChangeName,
  NameToImageIcon,
  bottomStatusBar,
  columnAndfilterSidebar,
  columnSidebarNoPivotNoColNoRow,
  filterSidebar,
  columnSidebar,
  loadingRenderer,
  filters,
  columnAndfilterVulnAdvSearchSidebarServerside,
  customVulnFiltersToolPanelComponent,
  columnAndfilterSidebarNoPivotNoColNoRow,
  getCompanyNInvestorFilterCardInfo,
  onFilterChangedExcelExportFilterCard,
};
