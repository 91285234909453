import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetDataAmendmentDataandAnalytics } from '../../../utils/toolsAmendmentDataandAnalytics-utils';

export const getDataAmendmentDataandAnalyticsReq = createAsyncThunk(
  'getDataAmendmentDataandAnalyticsReq',
  async (req) => {
    const response = await GetDataAmendmentDataandAnalytics(req);
    return response;
  }
);

const AmendmentDataandAnalyticsToolSlice = createSlice({
  name: 'amendmentDataandAnalyticsTool',
  initialState: {
    table_AmendmentDataandAnalytics: [],
    isLoadingAmendmentDataandAnalyticsTool: true,
  },
  reducers: {
    handleResetAmendmentData: {
      reducer(state, action) {
        state.isLoadingAmendmentDataandAnalyticsTool = true;
        state.table_AmendmentDataandAnalytics = [];
      },
      prepare(e) {
        return {
          payload: e
        };
      }
    }
  },
  extraReducers: {
    [getDataAmendmentDataandAnalyticsReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.table_AmendmentDataandAnalytics = action.payload.data;
      }
      state.isLoadingAmendmentDataandAnalyticsTool = false;
    },
  },
});

export const {
  handleResetAmendmentData
} = AmendmentDataandAnalyticsToolSlice.actions;

export default AmendmentDataandAnalyticsToolSlice.reducer;
