import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetCorporateDefenseScoreData } from '../../../utils/toolsCorporateDefenseScoreData-utils';

export const getCorporateDefenseScoreDataReq = createAsyncThunk('getCorporateDefenseScoreDataReq', async (company_search_id) => {
  const response = await GetCorporateDefenseScoreData(company_search_id);
  return response;
});

const CorporateDefenseScoreDataToolSlice = createSlice({
  name: 'corporateDefenseScoreDataTool',
  initialState: {
    tableCorporateDefenseScoreData: [],
    CorporateDefenseScoreDataHeaderTooltips: [],
    //
    isLoadingCorporateDefenseScoreDataTool: true,
  },
  reducers: {
    handleOnResetCorporateDefenseScoreData: {
      reducer(state) {
        state.tableCorporateDefenseScoreData = [];
        state.CorporateDefenseScoreDataHeaderTooltips = [];
        state.isLoadingCorporateDefenseScoreDataTool = true;
      },
      prepare() {
        return {};
      },
    },
  },
  extraReducers: {
    [getCorporateDefenseScoreDataReq.fulfilled]: (state, action) => {
      if (action.payload) {
        state.tableCorporateDefenseScoreData = action.payload !== undefined ? action.payload.data : [];
        state.CorporateDefenseScoreDataHeaderTooltips = action.payload !== undefined ? action.payload.tooltipData : [];
      }
      state.isLoadingCorporateDefenseScoreDataTool = action.payload === undefined;
    },
  },
});

export const { handleOnResetCorporateDefenseScoreData } = CorporateDefenseScoreDataToolSlice.actions;

export default CorporateDefenseScoreDataToolSlice.reducer;
