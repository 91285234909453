/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumnMenuContainer,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { nanoid } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import Table from '../GeneralForm/Table';

/**
 * @typedef {object} MuiDataGridDataSourceUnmanaged
 * @property {'unmanaged'} type
 * @property {any[]} rows
 * @property {boolean} isLoading
 * @property {'none'|'client'} paginationMode
 */

/**
 * @typedef {object} MuiDataGridDataSourceManaged
 * @property {'managed'} type
 * @property {Function} fetchMethod
 * @property {'none'|'client'|'server'} paginationMode
 */

/**
 * @typedef {object} MuiDataGridDataSourceStore
 * @property {'store'} type
 * @property {Function} fetchAction
 * @property {boolean} isLoading
 * @property {any[]} data
 * @property {'none'|'client'|'server'} paginationMode
 */

/**
 * @typedef {MuiDataGridDataSourceUnmanaged | MuiDataGridDataSourceManaged | MuiDataGridDataSourceStore} MuiDataGridDataSource
 */

/**
 * @typedef {object} MuiDataGridType
 * @property {"mui-data-grid"} type
 * @property {any[]} columns
 * @property {MuiDataGridDataSource} dataSource
 */

/**
 * @typedef {object} AgGridType
 * @property {"ag-grid"} type
 * @property {string} title
 * @property {any} gridOptions
 * @property {boolean} hideExcelDownloadIcon
 */

/**
 * @typedef {MuiDataGridType | AgGridType} DataGrid
 */

let AtlasGridColumnsPanel = null;
let GridFilterMenuItemWithCounter = null;
let GridToolbarFilterButtonWithCounter = null;

if (process.env.REACT_APP_IS_LENS === 'true') {
  import('@diligentcorp/atlas-react-data-grid-toolkit').then((atlasModule) => {
    AtlasGridColumnsPanel = atlasModule.AtlasGridColumnsPanel;
    GridFilterMenuItemWithCounter = atlasModule.GridFilterMenuItemWithCounter;
    GridToolbarFilterButtonWithCounter = atlasModule.GridToolbarFilterButtonWithCounter;
  });
}

/**
 * Wrapper for the DataGrid (Table)
 * @param {{ options: DataGrid }} { options }
 * @return {React.ReactElement}
 */
export function DataGrid({ options, ...props }) {
  if (options.type === 'mui-data-grid') {
    if (process.env.REACT_APP_IS_LENS !== 'true') {
      return <div>Can't use MuiDataGrid when LENS is off</div>;
    }

    switch (options.dataSource.type) {
      case 'unmanaged':
        return <MuiDataGridUnmanaged options={options} {...props} />;
      case 'managed':
        return <MuiDataGridManaged options={options} {...props} />;
      case 'store':
        return <MuiDataGridStore options={options} {...props} />;
      default:
        return null;
    }
  }

  return <AgGrid options={options} />;
}

const CustomColumnMenu = React.forwardRef((props, ref) => {
  const { hideMenu, currentColumn } = props;

  const defaultButtons = [<GridFilterMenuItemWithCounter key="filter" onClick={hideMenu} column={currentColumn} />];

  return (
    <GridColumnMenuContainer ref={ref} {...props}>
      {defaultButtons.map((button, index) =>
        // eslint-disable-next-line react/no-array-index-key
        React.cloneElement(button, { key: index, onClick: hideMenu, column: currentColumn }),
      )}
    </GridColumnMenuContainer>
  );
});

function CustomGridToolbar({ children = <GridToolbarFilterButton placeholder="Filters" /> }) {
  const quickFilterLabel = 'Search';
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter label={quickFilterLabel} placeholder="Search by..." />
      <GridToolbarColumnsButton placeholder="Columns" />
      {children}
    </GridToolbarContainer>
  );
}

function CustomGridToolbarWithFilterCounter() {
  return (
    <CustomGridToolbar>
      <GridToolbarFilterButtonWithCounter />
    </CustomGridToolbar>
  );
}

/**
 * @param {{ options: MuiDataGridType }} { options }
 * @return {React.ReactElement}
 */
function MuiDataGridUnmanaged({ options, ...props }) {
  // const [page, setPage] = useState(0);
  const [rows, setRows] = useState(options.dataSource.rows);

  return (
    <MuiDataGrid
      options={options}
      {...props}
      rows={options.dataSource.rows || []}
      isLoading={options.dataSource.isLoading}
    />
  );
}

/**
 * @param {{ options: MuiDataGridType }} { options }
 * @return {React.ReactElement}
 */
function MuiDataGridManaged({ options, ...props }) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    options.dataSource.fetchMethod().then((response) => {
      setRows(response.data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (options.dataSource.paginationMode !== 'server') {
      return;
    }

    setIsLoading(true);
    options.dataSource.fetchMethod(page).then((response) => {
      setRows(response.data);
      setIsLoading(false);
    });
  }, [page]);

  return (
    <MuiDataGrid
      options={options}
      {...props}
      rows={rows}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
    />
  );
}

/**
 * @param {{ options: MuiDataGridType }} { options }
 * @return {React.ReactElement}
 */
function MuiDataGridStore({ options, ...props }) {
  const [page, setPage] = useState(0);

  useEffect(() => {
    options.dataSource.fetchAction();
  }, []);

  useEffect(() => {
    if (options.dataSource.paginationMode !== 'server') {
      return;
    }

    options.dataSource.fetchAction({ page });
  }, [page]);

  return (
    <MuiDataGrid
      options={options}
      {...props}
      rows={options.dataSource.data || []}
      isLoading={options.dataSource.isLoading}
      page={page}
      setPage={setPage}
    />
  );
}

/**
 * @param {{ options: MuiDataGridType, rows: any[], isLoading: boolean, page?: number, setPage?: Function }} { options, rows, isLoading, page, setPage }
 * @return {React.ReactElement}
 */
function MuiDataGrid({ options, rows, isLoading, page, setPage, ...props }) {
  const {
    atlasThemeName,
    presets: {
      TablePresets: {
        TablePagination: { atlasTablePagination },
      },
      DataGridPresets: { noZebraStripes, noHeaderBackground },
    },
  } = useTheme();

  return (
    <DataGridPro
      {...props}
      getRowId={(row) => nanoid()}
      rows={rows}
      columns={options.columns}
      density="standard"
      disableSelectionOnClick={true}
      components={{
        Toolbar: CustomGridToolbarWithFilterCounter,
        ...{
          ColumnMenu: CustomColumnMenu,
        },
        ...{
          ColumnsPanel: AtlasGridColumnsPanel,
        },
      }}
      sx={{
        ...noHeaderBackground.componentsProps.grid.sx,
        minHeight: 400,
      }}
      loading={isLoading}
      paginationMode={options.dataSource.paginationMode === 'none' ? undefined : options.dataSource.paginationMode}
      page={page}
      onPageChange={(newPage) => setPage(newPage)}
      pageSizeOptions={[5, 10, 25]}
      pagination={options.dataSource.paginationMode !== 'none'}
    />
  );
}

/**
 * @param {{ options: AgGridType }} { options }
 * @return {React.ReactElement}
 */
function AgGrid({ options }) {
  return (
    <Table
      pageTitle={`Voting Rationale: ${options.title}`}
      title="Voting Rationale"
      gridOptions={options.gridOptions}
      IsShowCard={false}
      hideExcelDownloadIcon={options.hideExcelDownloadIcon}
    />
  );
}
